import { Link, graphql } from "gatsby"
import React from "react"
import Slide from "react-reveal/Slide"
import GA from "../components/ga"
import Hero from "../components/hero"
import InnerWrapper from "../components/innerWrapper"
import Layout from "../components/layout"
import SEO from "../SEO"
import styles from "./scss/services.module.scss"


class services extends React.Component {
  render() {
    const { data } = this.props
    return (
      <>
        <GA />
        <Layout>
          <SEO
            title="Services"
            pathname="/services"
            description={data.contentfulSeo.servicesPageSeoDescription}
          />
          <Hero
            slogan={data.contentfulServicesPageHeroSlogan.slogan}
            cta={false}
          />
          <section className={styles.services} id="services">
            <h2>Our Services</h2>
            <InnerWrapper width={1440}>
              <div className={styles.container}>
                {data.allContentfulService.edges.map((edge, index) => {
                  return (
                    <Slide left key={index}>
                      <div className={styles.service} key={index}>
                        <picture>
                          <source
                            srcSet={edge.node.icon.fixed.srcWebp}
                            type="image/webp"
                          />
                          <source
                            srcSet={edge.node.icon.fixed.src}
                            type="image/png"
                          />
                          <img
                            src={edge.node.icon.fixed.src}
                            alt={edge.node.title + " icon"}
                          />
                        </picture>
                        <h3>{edge.node.title}</h3>
                        <p>{edge.node.description}</p>
                        <Link style={{ textDecoration: "underline" }} to={edge.node.url} target="_blank">
                          See {edge.node.title} example
                        </Link>
                      </div>
                    </Slide>
                  )
                })}
              </div>

              {/* <div className={styles.more}>
                <Link to="/pricing/">
                  See Our Pricing <span>→</span>
                </Link>
              </div> */}
            </InnerWrapper>
          </section>
        </Layout>

      </>
    )
  }
}

export const query = graphql`
  query ServicesQuery {
    contentfulSeo {
      servicesPageSeoDescription
    }
    allContentfulService {
      edges {
        node {
          title
          description
          icon {
            fixed(width: 200, height: 200, quality: 100) {
              srcWebp
              src
            }
          }
          url
        }
      }
    }
    contentfulServicesPageHeroSlogan {
      slogan
    }
  }
`

export default services
